const load_animations = async () => {
  await import('~/javascripts/slide_reveal');
  await import('~/javascripts/animations/home');
};

const trigger = document.getElementById('header-scroll-observer');
const triggerObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        return;
      }

      load_animations();

      triggerObserver.unobserve(trigger);
    });
  },
  {
    rootMargin: '80px',
  }
);

triggerObserver.observe(trigger);

const videoWrapper = document.getElementById('video-wrapper');
const video = document.getElementById('video');
const videoWidthPercentage =
  (video.clientWidth / videoWrapper.clientWidth) * 100;
const windowHeight = document.documentElement.clientHeight;
window.addEventListener('scroll', function (event) {
  var scrollVal = window.scrollY + windowHeight;

  if (scrollVal > videoWrapper.offsetTop * 1.1) {
    const percentage =
      ((scrollVal - videoWrapper.offsetTop * 1.1) / windowHeight) * 100;

    if (percentage > videoWidthPercentage) {
      video.style.width = Math.min(percentage, 100) + '%';
    }
  }
});
